<template>
  <v-container>
    <h2 class="mt-5 mb-3">Managers</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openManagerDialog()">Add a manager</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="managers"
        :search="search"
        sort-by="discordId"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search managers"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openManagerDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="managerDialog" max-width="800">
      <v-card>
        <v-card-title>{{selectedManager.id ? 'Edit' : 'New'}} manager</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="managerForm"
                  v-model="validManager">
                <v-text-field
                    v-model="selectedManager.discordId"
                    label="Discord ID"
                    outlined
                    required></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteManager" v-if="selectedManager.id">Delete manager</v-btn>
          <v-btn color="primary" text @click="saveManager">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Managers',
    components: {
    },

    data() {
      return {
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Discord ID',
            value: 'discordId'
          },
          {
            text: '',
            value: 'edit',
            filterable: false,
            sortable: false
          }
        ],
        search: '',
        managers: [],
        snackbar: false,
        snackbarColor: 'success',
        snackbarText: '',
        managerDialog: false,
        validManager: false,
        selectedManager: {}
      }
    },

    methods: {
      deleteManager() {
        this.$confirm('Do you really want to delete this manager?').then(res => {
          if (res) {
            axios.delete('/manager/' + this.selectedManager.id, {
              baseURL: process.env.VUE_APP_ORCHESTRATOR_URL
            })
              .then(() => {
                axios.get('/manager', {
                  baseURL: process.env.VUE_APP_ORCHESTRATOR_URL
                })
                  .then(response => {
                    this.managers = response.data;
                    this.snackbar = true;
                    this.snackbarText = 'Manager deleted';
                    this.snackbarColor = 'error';
                    this.managerDialog = false;
                  });
              }).catch(() => {
              this.snackbar = true;
              this.snackbarText = 'Error deleting manager';
              this.snackbarColor = 'error';
            });
          }
        });
      },

      saveManager() {
        if (!this.$refs.managerForm.validate()) {
          return;
        }

        axios.request({
          url: '/manager',
          method: this.selectedManager.id ? 'put' : 'post',
          data: this.selectedManager,
          baseURL: process.env.VUE_APP_ORCHESTRATOR_URL
        })
          .then(() => {
            axios.get('/manager', {
              baseURL: process.env.VUE_APP_ORCHESTRATOR_URL
            })
              .then(response => {
                this.managerDialog = false;
                this.managers = response.data;
                this.snackbar = true;
                this.snackbarText = 'Manager saved successfully';
                this.snackbarColor = 'success';
              });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error saving manager';
            this.snackbarColor = 'error';
          });
      },

      openManagerDialog(manager) {
        this.managerDialog = true;
        if (!manager) {
          manager = {};
        }
        this.selectedManager = JSON.parse(JSON.stringify(manager));
      }
    },

    created() {
      axios.get('/manager', {
        baseURL: process.env.VUE_APP_ORCHESTRATOR_URL
      })
        .then(response => {
          this.managers = response.data
        });
    }
  }
</script>
