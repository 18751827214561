<template>
  <v-card>
    <v-card-title v-if="pack">
      Check pack "{{pack.name}}"
      <v-btn icon v-on:click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="check">
      <v-checkbox label="Draft" v-model="draft" />

      <ul>
        <li>
          <v-icon>mdi-information</v-icon>
          {{check.items.length}} items and {{check.items.length - visibleItems}} are not visible
        </li>
        <li v-if="check.items.length > 0">
          <v-icon>mdi-information</v-icon>
          Theme used: {{check.items[0].theme.name}}
        </li>
        <li>
          <v-icon color="success" v-if="draft && !pack.isVisible || !draft && pack.isVisible">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          Pack {{pack.isVisible ? '' : 'not'}} visible
        </li>
        <li v-for="fusion in check.fusions" :key="fusion.item.id">
          <v-icon color="success" v-if="!fusion.item.isInvocable && fusion.items.length > 0">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          Fusion "{{fusion.item.name}}" requires {{fusion.items.length}} items and scores are {{fusion.item.scores[0]}}/<span class="amber--text">{{fusion.item.scores[1]}}</span>
        </li>
        <li>
          <v-icon color="success" v-if="draft && visibleItems === 0 || !draft && visibleItems > 0">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{visibleItems}} items are visible
        </li>
        <li>
          <v-icon color="success" v-if="draft && invocableItems === 0 || !draft && invocableItems === visibleItems - check.fusions.length">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{invocableItems}} items are invocable
        </li>
        <li>
          <v-icon color="success" v-if="draft && craftableItems === 0 || !draft && craftableItems === visibleItems - check.fusions.length">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{craftableItems}} items are craftable
        </li>
        <li>
          <v-icon color="success" v-if="draft && goldableItems === 0 || !draft && goldableItems === visibleItems - check.fusions.length">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{goldableItems}} items are goldable
        </li>
        <li>
          <v-icon color="success" v-if="tradableItems === 0">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{tradableItems}} items are tradable
        </li>
        <li>
          <v-icon color="success" v-if="draft && recyclableItems === 0 || !draft && recyclableItems === visibleItems - rarity5Items">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{recyclableItems}} items are recyclable
        </li>
        <li>
          <v-icon color="success" v-if="countingItems === check.items.length">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          {{countingItems}} items are counting
        </li>
        <li>
          <v-icon color="success" v-if="check.tower">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.tower">Tower is "{{check.tower.name}}" and tower reputation is "{{check.tower.reputation.name}}"</span>
          <span v-else>No tower</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.towerSeason">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.towerSeason">Tower season goes from {{check.towerSeason.beginDate}} to {{check.towerSeason.endDate}}</span>
          <span v-else>No tower season</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.reputationLevels && check.reputationLevels.length === 8">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.reputationLevels">{{check.reputationLevels.length}} reputation levels</span>
          <span v-else>No reputation levels</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.towerParticipationAchievement
          && check.towerParticipationAchievement.isVisible
          && check.towerParticipationAchievement.category.name === 'Le Vortex'
          && !check.towerParticipationAchievement.featOfStrength
          && !check.towerParticipationAchievement.isDeletable
          && !check.towerParticipationAchievement.rewardItem
          && !check.towerParticipationAchievement.rewardBanner
          && !check.towerParticipationAchievement.rewardLoreDust">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.towerParticipationAchievement">Tower participation achievement is "{{check.towerParticipationAchievement.name}}"</span>
          <span v-else>No tower participation achievement</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.completionAchievement
          && check.completionAchievement.isVisible
          && check.completionAchievement.category.name === 'Collection'
          && !check.completionAchievement.featOfStrength
          && check.completionAchievement.isDeletable
          && !check.completionAchievement.rewardItem
          && check.completionAchievement.rewardBanner
          && !check.completionAchievement.rewardLoreDust">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.completionAchievement">Completion achievement is "{{check.completionAchievement.name}}"</span>
          <span v-else>No completion achievement</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.completionAchievement
          && check.completionAchievement.rewardBanner
          && (check.completionAchievement.rewardBanner.isVisible && !draft || !check.completionAchievement.rewardBanner.isVisible && draft)">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.completionAchievement && check.completionAchievement.rewardBanner">
            Achievement banner is "{{check.completionAchievement.rewardBanner.name}}" ({{check.completionAchievement.rewardBanner.isVisible ? 'Visible' : 'Not visible'}})
            <v-img width="50%" :src="check.completionAchievement.rewardBanner.image.url"/>
          </span>
          <span v-else>No achievement banner</span>
        </li>
        <li>
          <v-icon color="success" v-if="check.events.length === 1
          && check.events[0].image
          && check.events[0].balanceCost === 1000
          && check.events[0].loreDustCost === 0
          && !check.events[0].isOneTime
          && check.events[0].beginDate
          && check.events[0].endDate
          && check.events[0].isPityEnabled
          && check.events[0].isStarEnabled
          && !check.events[0].isInversePack
          && !check.events[0].isSubscription
          && check.events[0].isPreventDuplicate
          && check.events[0].dailyLimit === 3
          ">mdi-check</v-icon>
          <v-icon color="error" v-else>mdi-alert</v-icon>
          <span v-if="check.events.length === 1">
            Event is "{{check.events[0].name}}" and goes from {{check.events[0].beginDate}} to {{check.events[0].endDate}} and is limited to {{check.events[0].dailyLimit}} usages
            <v-img v-if="check.events[0].image" width="50%" :src="check.events[0].image.url"/>
            <div v-else>Event has no image</div>
          </span>
          <span v-else>{{check.events.length}} events</span>
        </li>
      </ul>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: 'CheckPack',

  props: {
    pack: {
      required: true
    }
  },

  data() {
    return {
      check: null,
      draft: false,
      visibleItems: 0,
      invocableItems: 0,
      craftableItems: 0,
      goldableItems: 0,
      tradableItems: 0,
      recyclableItems: 0,
      countingItems: 0,
      rarity5Items: 0
    }
  },

  watch: {
    pack() {
      this.reload();
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    reload() {
      this.check = null;
      axios.get('/pack/check?packId=' + this.pack.id)
          .then(response => {
            this.check = response.data;
            this.visibleItems = 0;
            this.invocableItems = 0;
            this.craftableItems = 0;
            this.goldableItems = 0;
            this.tradableItems = 0;
            this.recyclableItems = 0;
            this.countingItems = 0;
            this.rarity5Items = 0;

            this.check.items.forEach(item => {
              this.visibleItems += item.isVisible ? 1 : 0;
              this.invocableItems += item.isInvocable ? 1 : 0;
              this.craftableItems += item.isCraftable ? 1 : 0;
              this.goldableItems += item.isGoldable ? 1 : 0;
              this.tradableItems += item.isTradable ? 1 : 0;
              this.recyclableItems += item.isRecyclable ? 1 : 0;
              this.countingItems += item.isCounting ? 1 : 0;
              this.rarity5Items += item.rarity === 5 ? 1 : 0;
            })

            // TODO Check Fusion (with score)
          });
    }
  }
}
</script>