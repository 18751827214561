<template>
  <v-container>
    <h2 class="mt-5 mb-3">Packs</h2>

    <v-data-table
        :headers="headers"
        :items="packs"
        :search="search"
        sort-by="index"
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search packs"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.isVisible="{ item }">
        <boolean-property :value="item.isVisible" />
      </template>
      <template v-slot:item.isTemporary="{ item }">
        <boolean-property :value="item.isTemporary" />
      </template>
      <template v-slot:item.preview="{ item }">
        <a target="_blank" :href="buildPreviewUrl(item)">
          {{buildPreviewUrl(item)}}
        </a>
      </template>
      <template v-slot:item.check="{ item }">
        <v-btn text v-on:click="checkPack(item)">
          <v-icon>mdi-check</v-icon> Check pack
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="checkDialog" max-width="800" eager>
      <check-pack :pack="checkedPack" v-on:close="checkDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import BooleanProperty from "@/components/BooleanProperty";
import CheckPack from "@/components/CheckPack";

export default {
  name: 'Packs',

  components: {
    CheckPack,
    BooleanProperty
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Year',
          value: 'year'
        },
        {
          text: 'Visible',
          value: 'isVisible'
        },
        {
          text: 'Temporary',
          value: 'isTemporary'
        },
        {
          text: 'Preview',
          value: 'preview'
        },
        {
          text: 'Check',
          value: 'check'
        },
      ],
      search: '',
      packs: [],
      checkDialog: false,
      checkedPack: null
    }
  },

  methods: {
    checkPack(pack) {
      this.checkDialog = true;
      this.checkedPack = pack;
    },

    buildPreviewUrl(pack) {
      return process.env.VUE_APP_PREVIEW_URL + '/?packId=' + pack.id;
    },
  },

  created() {
    axios.get('/pack')
      .then(response => {
        this.packs = response.data
      });
  }
}
</script>
