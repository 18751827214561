<template>
  <v-container>
    <h2 class="mt-5 mb-3">Tickets</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openTicketDialog()">Add a ticket</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="tickets"
        :search="search"
        sort-by="beginDate"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search tickets"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.user="{ item }">
        {{item.user.account.discordUserName}} ({{item.user.ruleSetType.toLowerCase()}})
      </template>
      <template v-slot:item.pack="{ item }">
        {{item.pack.name}} {{item.pack.year}}
      </template>
      <template v-slot:item.expiringDate="{ item }">
        {{formatDate(item.expiringDate)}}
      </template>
      <template v-slot:item.rarity="{ item }">
        <span v-for="index in item.rarity" :key="index">⭐</span>
      </template>
      <template v-slot:item.isInversePack="{ item }">
        <boolean-property :value="item.isInversePack" />
      </template>
      <template v-slot:item.isUsed="{ item }">
        <boolean-property :value="item.isUsed" />
      </template>
      <template v-slot:item.link="{ item }">
        <a target="_blank" :href="buildWebUrl(item)">{{buildWebUrl(item)}}</a>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openTicketDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="ticketDialog" max-width="800">
      <v-card>
        <v-card-title>{{selectedTicket.id ? 'Edit' : 'New'}} ticket</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="ticketForm"
                  v-model="validTicket">
                <user-autocomplete label="User"
                                   :rules="[v => !!v || 'User is required']"
                                   v-model="selectedTicket.user"></user-autocomplete>
                <v-datetime-picker label="Expiring date"
                                   :rules="[v => !!v || 'Expiring date is required']"
                                   v-model="selectedTicket.expiringDate"></v-datetime-picker>
                <v-select
                    v-model="selectedTicket.rarity"
                    :items="rarity"
                    :rules="[v => !!v || 'Rarity is required']"
                    item-text="label"
                    item-value="value"
                    label="Rarity"
                    outlined
                    required></v-select>
                <v-select v-model="selectedTicket.pack"
                          label="Pack"
                          :items="packs"
                          item-value="id"
                          :item-text="getPackText"
                          :rules="[v => !!v || 'Pack is required']"
                          outlined
                          return-object>
                </v-select>
                <v-checkbox
                    v-model="selectedTicket.isInversePack"
                    label="Inverse pack"></v-checkbox>
                <v-checkbox
                    v-model="selectedTicket.isUsed"
                    label="Used"></v-checkbox>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteTicket" v-if="selectedTicket.id">Delete ticket</v-btn>
          <v-btn color="primary" text @click="saveTicket">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import {format, parseISO} from 'date-fns';
import VDatetimePicker from "../components/DatetimePicker";
import BooleanProperty from "../components/BooleanProperty";
import UserAutocomplete from "../components/UserAutocomplete";

export default {
    name: 'Tickets',
    components: {
      UserAutocomplete,
      BooleanProperty,
      VDatetimePicker
    },

    data() {
      return {
        headers: [
          {
            text: 'User',
            value: 'user'
          },
          {
            text: 'Expiring date',
            value: 'expiringDate'
          },
          {
            text: 'Rarity',
            value: 'rarity'
          },
          {
            text: 'Pack',
            value: 'pack'
          },
          {
            text: 'Inverse pack',
            value: 'isInversePack'
          },
          {
            text: 'Used',
            value: 'isUsed'
          },
          {
            text: 'Link',
            value: 'link'
          },
          {
            text: '',
            value: 'edit',
            filterable: false,
            sortable: false
          }
        ],
        search: '',
        tickets: [],
        packs: [],
        snackbar: false,
        snackbarColor: 'success',
        snackbarText: '',
        ticketDialog: false,
        validTicket: false,
        selectedTicket: {},
        rarity: [
          { value: 1, label: '⭐' },
          { value: 2, label: '⭐⭐' },
          { value: 3, label: '⭐⭐⭐' },
          { value: 4, label: '⭐⭐⭐⭐' },
          { value: 5, label: '⭐⭐⭐⭐⭐' }
        ]
      }
    },

    methods: {
      getPackText(pack) {
        return `${pack.name} ${pack.year ? pack.year : ''}`
      },

      buildWebUrl(ticket) {
        return process.env.VUE_APP_WEB_URL + '/ticket/' + ticket.id;
      },

      formatDate(dateString) {
        if (!dateString) {
          return '';
        }
        return format(parseISO(dateString), 'dd/MM/yyyy HH:mm');
      },

      deleteTicket() {
        this.$confirm('Do you really want to delete this ticket?').then(res => {
          if (res) {
            axios.delete('/ticket/' + this.selectedTicket.id)
              .then(() => {
                axios.get('/ticket')
                  .then(response => {
                    this.tickets = response.data;
                    this.snackbar = true;
                    this.snackbarText = 'Ticket deleted';
                    this.snackbarColor = 'error';
                    this.ticketDialog = false;
                  });
              }).catch(() => {
              this.snackbar = true;
              this.snackbarText = 'Error deleting ticket';
              this.snackbarColor = 'error';
            });
          }
        });
      },

      saveTicket() {
        if (!this.$refs.ticketForm.validate()) {
          return;
        }

        axios.post('/ticket', this.selectedTicket)
          .then(() => {
            axios.get('/ticket')
              .then(response => {
                this.ticketDialog = false;
                this.tickets = response.data;
                this.snackbar = true;
                this.snackbarText = 'Ticket saved successfully';
                this.snackbarColor = 'success';
              });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error saving ticket';
            this.snackbarColor = 'error';
          });
      },

      openTicketDialog(ticket) {
        this.ticketDialog = true;
        if (!ticket) {
          ticket = {};
        }
        this.selectedTicket = JSON.parse(JSON.stringify(ticket));
      }
    },

    created() {
      axios.get('/ticket')
        .then(response => {
          this.tickets = response.data
        });

      axios.get('/pack')
        .then(response => {
          this.packs = response.data
        });
    }
  }
</script>
