<template>
  <v-container>
    <h2 class="mt-5 mb-3">Posts</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openPostDialog()">Add a post</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="posts"
        sort-by="date"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:item.isPublished="{ item }">
        <boolean-property :value="item.isPublished" />
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openPostDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="postDialog" max-width="1800">
      <v-card>
        <v-card-title>
          {{ selectedPost.id ? 'Edit' : 'New' }} post
          <v-spacer></v-spacer>
          <v-btn icon v-on:click="postDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="postForm"
                  v-model="validPost">
                <v-text-field
                    v-model="selectedPost.title"
                    label="Title"
                    :rules="[v => !!v || 'Title is required']"
                    outlined
                    required></v-text-field>

                <v-row>
                  <v-col cols="6">
                    <document-upload
                        v-model="selectedPost.image"></document-upload>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                        v-model="selectedPost.isPublished"
                        label="Published"></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-textarea v-model="selectedPost.markdown"
                                label="Markdown"
                                :rules="[v => !!v || 'Markdown is required']"
                                outlined
                                auto-grow
                                required></v-textarea>
                  </v-col>
                  <v-col cols="6"
                         class="markdown-content"
                         v-html="render(selectedPost.markdown)">
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deletePost" v-if="selectedPost.id">Delete post</v-btn>
          <v-btn color="primary" text @click="savePost">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.markdown-content .center {
  text-align: center;
}

.markdown-content ul {
  margin-bottom: 16px;
}
</style>

<script>
import axios from 'axios';
import DocumentUpload from '../components/DocumentUpload';
import MarkdownIt from 'markdown-it';
import MarkdownItContainer from 'markdown-it-container';
import MarkdownItImSize from 'markdown-it-imsize';
import MarkdownItEmoji from 'markdown-it-emoji';
import BooleanProperty from "@/components/BooleanProperty";

export default {
  name: 'Posts',
  components: {
    BooleanProperty,
    DocumentUpload
  },

  data() {
    return {
      headers: [
        {
          text: 'Published',
          value: 'isPublished'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Created by',
          value: 'createdBy'
        },
        {
          text: '',
          value: 'edit',
          filterable: false,
          sortable: false
        }
      ],
      posts: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      postDialog: false,
      validPost: false,
      selectedPost: {},
    }
  },

  methods: {
    render(markdown) {
      if (!markdown) {
        return '';
      }

      const md = MarkdownIt()
          .use(MarkdownItContainer, 'center', {})
          .use(MarkdownItImSize)
          .use(MarkdownItEmoji, {
            defs: {
              ':ZU:': '859802750622367814',
              ':PoussiereDeLore:': '814855188435173446',
              ':Poudrecreatrice:': '948289012693610546',
              ':Cristaldhistoire:': '948578144670089216',
              ':Rayou1:': '784479846826770512',
              ':Rayou2:': '813929235596050473',
              ':Rayou3:': '784479846775783487',
              ':Rayou4:': '784479846457671742',
              ':Rayou5:': '784479846847086602',
              ':RayouDor:': '814944896951386143',
              ':ZMonnaie:': '784483192182800387',
              ':ZUMonnaie:': '948286077473546280',
              ':EraOh:': '853390224834691132',
              ':STAR:': '885148147846905887',
              ':LR:': '886253162112102451',
              ':zrtMarteau:': '937787477236809758',
              ':zrtMdr:': '936715430603673600',
              ':eclatdetoile:': '1030905587896164462',
            },
            shortcuts: {
              ':ZU:': [ ':ZU:' ],
              ':PoussiereDeLore:': [ ':PoussiereDeLore:' ],
              ':Poudrecreatrice:': [ ':Poudrecreatrice:' ],
              ':Cristaldhistoire:': [ ':Cristaldhistoire:' ],
              ':Rayou1:': [ ':Rayou1:' ],
              ':Rayou2:': [ ':Rayou2:' ],
              ':Rayou3:': [ ':Rayou3:' ],
              ':Rayou4:': [ ':Rayou4:' ],
              ':Rayou5:': [ ':Rayou5:' ],
              ':RayouDor:': [ ':RayouDor:' ],
              ':ZMonnaie:': [ ':ZMonnaie:' ],
              ':ZUMonnaie:': [ ':ZUMonnaie:' ],
              ':EraOh:': [ ':EraOh:' ],
              ':STAR:': [ ':STAR:' ],
              ':LR:': [ ':LR:' ],
              ':zrtMarteau:': [ ':zrtMarteau:' ],
              ':zrtMdr:': [ ':zrtMdr:' ],
              ':eclatdetoile:': [ ':eclatdetoile:' ],
            }
          });

      md.renderer.rules.emoji = function(token, idx) {
        return '<img width="20" src="https://cdn.discordapp.com/emojis/' + token[idx].content + '.png"/>';
      };

      return md.render(markdown)
    },

    deletePost() {
      this.$confirm('Do you really want to delete this post?').then(res => {
        if (res) {
          axios.delete('/post/' + this.selectedPost.id)
              .then(() => {
                axios.get('/post')
                    .then(response => {
                      this.posts = response.data;
                      this.snackbar = true;
                      this.snackbarText = 'Post deleted';
                      this.snackbarColor = 'error';
                      this.postDialog = false;
                    });
              }).catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error deleting post';
            this.snackbarColor = 'error';
          });
        }
      });
    },

    savePost() {
      if (!this.$refs.postForm.validate()) {
        return;
      }

      // Render markdown to HTML client-side
      this.selectedPost.content = this.render(this.selectedPost.markdown);

      axios.post('/post', this.selectedPost)
          .then(() => {
            axios.get('/post')
                .then(response => {
                  this.postDialog = false;
                  this.posts = response.data;
                  this.snackbar = true;
                  this.snackbarText = 'Post saved successfully';
                  this.snackbarColor = 'success';
                });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error saving post';
            this.snackbarColor = 'error';
          });
    },

    openPostDialog(post) {
      this.postDialog = true;
      if (!post) {
        post = {};
      }
      this.selectedPost = JSON.parse(JSON.stringify(post));
    }
  },

  created() {
    axios.get('/post')
        .then(response => {
          this.posts = response.data
        })
  }
}
</script>
