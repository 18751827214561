<template>
  <v-container>
    <h2 class="mt-5 mb-3">Towers</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openTowerDialog()">Add a tower</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="towers"
        :search="search"
        sort-by="index"
        sort-asc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search towers"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.towerFloors="{ item }">
        {{item.towerFloors.length}}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openTowerDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="towerDialog" max-width="1000">
      <v-card>
        <v-card-title>{{selectedTower.id ? 'Edit' : 'New'}} tower</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="towerForm"
                  v-model="validTower">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        v-model="selectedTower.name"
                        label="Name"
                        :rules="[v => !!v || 'Name is required']"
                        outlined
                        required></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select v-model="selectedTower.pack"
                              label="Pack"
                              :items="packs"
                              item-value="id"
                              :item-text="getPackText"
                              :rules="[v => !!v || 'Pack is required']"
                              outlined
                              return-object>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="selectedTower.reputation"
                              label="Reputation"
                              :items="reputations"
                              item-value="id"
                              item-text="name"
                              :rules="[v => !!v || 'Reputation is required']"
                              outlined
                              return-object>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                        v-model="selectedTower.index"
                        label="Order"
                        type="number"
                        :rules="[v => !isNaN(parseInt(v)) || 'Order is required']"
                        outlined
                        required></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <document-upload
                        v-model="selectedTower.image"></document-upload>
                  </v-col>
                </v-row>

                <div v-for="(towerFloor, index) in selectedTower.towerFloors" :key="index">
                  <h2 class="mb-4">Floor {{index + 1}}</h2>
                  <v-row>
                    <v-col cols="8">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="towerFloor.rate"
                              label="Success rate %"
                              type="number"
                              :rules="[v => !isNaN(parseInt(v)) || 'Success rate is required']"
                              outlined
                              required></v-text-field>
                        </v-col>
                      </v-row>

                      <v-combobox multiple
                                  v-model="towerFloor.successMessages"
                                  label="Success messages"
                                  outlined
                                  :allow-overflow="false"
                                  chips
                                  deletable-chips>
                        <template v-slot:selection="{ item, parent }">
                          <v-chip close @click:close="parent.selectItem(item)" style="height: auto">
                            <span style="overflow-wrap: break-word; white-space: normal; margin: 4px">{{ item }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                      <v-combobox multiple
                                  v-model="towerFloor.failureMessages"
                                  label="Failure messages"
                                  outlined
                                  :allow-overflow="false"
                                  chips
                                  deletable-chips>
                        <template v-slot:selection="{ item, parent }">
                          <v-chip close @click:close="parent.selectItem(item)" style="height: auto">
                            <span style="overflow-wrap: break-word; white-space: normal; margin: 4px">{{ item }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                      <v-btn class="mb-6" v-if="index > 0" v-on:click="copyFloorMessages(selectedTower.towerFloors[0], towerFloor)">Copy floor 1 messages</v-btn>

                      <h3 class="mb-4">Drop rates for floor {{index + 1}}</h3>
                      <v-row>
                      <v-col cols="6" v-for="(dropRate, index0) in towerFloor.dropRates" :key="index0">
                        <v-row justify="center">
                          <v-col cols="6" class="text-center white--text">
                            <span v-for="rarityIndex in dropRate.rarity" :key="rarityIndex">⭐</span><br/>{{dropRate.shinyLevel === 1 ? 'Golden' : 'Normal'}}
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                                v-model="dropRate.rate"
                                label="Drop rate %"
                                type="number"
                                :rules="[v => !isNaN(parseInt(v)) || 'Drop rate is required']"
                                outlined
                                required></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">Try</th>
                            <th class="text-left">Worth</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="tryIndex of 28" :key="tryIndex"
                              v-bind:class="{ 'red--text': worthAscension(index, tryIndex, selectedTower, recycleConfigs) < 0, 'green--text': worthAscension(index, tryIndex, selectedTower, recycleConfigs) > 0 }">
                            <td>{{tryIndex}}</td>
                            <td>{{worthAscension(index, tryIndex, selectedTower, recycleConfigs)}}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveTower">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import DocumentUpload from "@/components/DocumentUpload";

export default {
    name: 'Towers',
    components: {
      DocumentUpload
    },

    data() {
      return {
        headers: [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Order',
            value: 'index'
          },
          {
            text: 'Floors',
            value: 'towerFloors'
          },
          {
            text: '',
            value: 'edit',
            filterable: false,
            sortable: false
          }
        ],
        search: '',
        towers: [],
        packs: [],
        reputations: [],
        recycleConfigs: [],
        snackbar: false,
        snackbarColor: 'success',
        snackbarText: '',
        towerDialog: false,
        validTower: false,
        selectedTower: {}
      }
    },

    methods: {
      getPackText(pack) {
        return `${pack.name} ${pack.year ? pack.year : ''}`
      },

      saveTower() {
        if (!this.$refs.towerForm.validate()) {
          return;
        }

        axios.post('/tower', this.selectedTower)
          .then(() => {
            axios.get('/tower')
              .then(response => {
                this.towerDialog = false;
                this.towers = response.data;
                this.snackbar = true;
                this.snackbarText = 'Tower saved successfully';
                this.snackbarColor = 'success';
              });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error saving tower';
            this.snackbarColor = 'error';
          });
      },

      openTowerDialog(tower) {
        this.towerDialog = true;
        if (!tower) {
          tower = {
            "towerFloors": [
              {
                "index": 0,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              },
              {
                "index": 1,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              },
              {
                "index": 2,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              },
              {
                "index": 3,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              },
              {
                "index": 4,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              },
              {
                "index": 5,
                "dropRates": [
                  {
                    "rarity": 1,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 2,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 3,
                    "shinyLevel": 0
                  },
                  {
                    "rarity": 4,
                    "shinyLevel": 0
                  }
                ],
                "successMessages": [],
                "failureMessages":[]
              }
            ]
          };
        }
        this.selectedTower = JSON.parse(JSON.stringify(tower));
      },

      towerFloorGain(dropRates, recycleConfigs) {
        return dropRates[0].rate / 100. * recycleConfigs[0].craftValue + dropRates[1].rate / 100. * recycleConfigs[1].craftValue + dropRates[2].rate / 100. * recycleConfigs[2].craftValue + dropRates[3].rate / 100. * recycleConfigs[3].craftValue;
      },

      worthAscension(floorIndex, tryIndex, tower, recycleConfigs) {
        if (recycleConfigs.length === 0) {
          return null;
        }
        if (floorIndex >= tryIndex) {
          return null;
        }

        let cost = tryIndex * 20; // Cost of one ascension set in stone, should get from /config
        let avgGain = 0;
        for (let i = 0; i <= floorIndex; i++) {
          avgGain += this.towerFloorGain(tower.towerFloors[i].dropRates, recycleConfigs);
        }
        return Math.round(avgGain - cost);
      },

      copyFloorMessages(fromFloor, toFloor) {
        toFloor.successMessages = [...fromFloor.successMessages];
        toFloor.failureMessages = [...fromFloor.failureMessages];
      }
    },

    created() {
      axios.get('/tower')
        .then(response => {
          this.towers = response.data
        });

      axios.get('/pack')
        .then(response => {
          this.packs = response.data
        });

      axios.get('/reputation')
        .then(response => {
          this.reputations = response.data
        });

      axios.get('/public/recycle/config')
        .then(response => {
          this.recycleConfigs = response.data
        });
    }
  }
</script>
