<template>
  <v-container>
    <h2 class="mt-5 mb-3">Fusions</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openFusionDialog()">Add a fusion</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="filteredFusions"
        :search="search"
        sort-by="rarity"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-row>
          <v-col cols="10">
            <v-text-field
                v-model="search"
                label="Search fusions"
                append-icon="mdi-magnify"
                class="mx-4"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-select
                v-model="searchPackId"
                label="Pack"
                :items="packs"
                item-value="id"
                class="mx-4"
                :item-text="getPackText">
            </v-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.item="{ item }">
        <span v-for="index in item.item.rarity" :key="index">⭐</span>
        {{item.item.name}}
      </template>
      <template v-slot:item.items="{ item }">
        <v-chip v-for="(it, index) in item.items" :key="index"
                class="ma-1">
          <span v-for="index in it.rarity" :key="index">⭐</span>
          {{it.name}}
        </v-chip>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openFusionDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="fusionDialog" max-width="800">
      <v-card>
        <v-card-title>{{selectedFusion.id ? 'Edit' : 'New'}} fusion</v-card-title>
        <v-card-text>
          <v-form
              ref="fusionForm"
              v-model="validFusion">
            <v-select v-model="selectedFusion.item"
                      label="Item"
                      :items="filteredItems"
                      item-value="id"
                      item-text="name"
                      :rules="[v => !!v || 'Item is required']"
                      outlined
                      return-object>
              <template v-slot:selection="data">
                <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
              </template>
              <template v-slot:item="data">
                <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
              </template>
            </v-select>

            <v-row>
              <v-col cols="4">
                <v-select v-model="searchPackId"
                          label="Pack"
                          :items="packs"
                          item-value="id"
                          :item-text="getPackText"
                          outlined>
                </v-select>
              </v-col>
              <v-col cols="8">
                <v-autocomplete v-model="selectedItem"
                          label="Item"
                          :items="filteredItems"
                          item-value="id"
                          item-text="name"
                          v-on:change="addItem(selectedItem)"
                          outlined
                          return-object>
                  <template v-slot:selection="data">
                    <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
                  </template>
                  <template v-slot:item="data">
                    <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
                  </template>
                  <template v-slot:no-data>
                    <div class="ma-4">
                      Select a pack first
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Item</th>
                  <th class="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in orderedItems" :key="index">
                  <td><span v-for="index in item.rarity" :key="index">⭐</span> {{item.name}}</td>
                  <td class="text-center">
                    <v-btn icon rounded small color="black" class="white" v-on:click="addItem(item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn icon rounded small color="black" class="white ml-2" v-on:click="removeItem(item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteFusion" v-if="selectedFusion.id">Delete fusion</v-btn>
          <v-btn color="primary" text @click="saveFusion">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Fusions',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'item',
          width: 400
        },
        {
          text: 'Items',
          value: 'items'
        },
        {
          text: '',
          value: 'edit',
          filterable: false
        }
      ],
      search: '',
      searchPackId: null,
      fusions: [],
      items: [],
      packs: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      fusionDialog: false,
      validFusion: false,
      currentFusion: {},
      selectedFusion: {},
      selectedItem: null,
      rarity: [
        { value: 1, label: '⭐' },
        { value: 2, label: '⭐⭐' },
        { value: 3, label: '⭐⭐⭐' },
        { value: 4, label: '⭐⭐⭐⭐' },
        { value: 5, label: '⭐⭐⭐⭐⭐' }
      ]
    }
  },

  computed: {
    filteredFusions() {
      if (!this.searchPackId) {
        return this.fusions;
      }

      return this.fusions.filter(fusion => fusion.item.pack.id === this.searchPackId);
    },

    filteredItems() {
      if (!this.searchPackId) {
        return this.items;
      }

      return this.items.filter(item => item.pack.id === this.searchPackId);
    },

    orderedItems() {
      if (!this.selectedFusion.items) {
        return []
      }

      return this.selectedFusion.items.slice().sort((item1, item2) => (item2.rarity - item1.rarity) || (item1.name.localeCompare(item2.name)));
    }
  },

  watch: {
    searchPackId() {
      if (this.searchPackId) {
        localStorage.setItem('searchPackId', this.searchPackId)
      } else {
        localStorage.removeItem('searchPackId')
      }
    }
  },

  methods: {
    getPackText(pack) {
      return `${pack.name} ${pack.year ? pack.year : ''}`
    },

    addItem(item) {
      if (!item) {
        return;
      }
      this.selectedFusion.items.push(JSON.parse(JSON.stringify(item)));
      this.$nextTick(() => {
        this.selectedItem = null;
      });
    },

    removeItem(item) {
      if (!item) {
        return;
      }
      this.selectedFusion.items = this.selectedFusion.items.filter(it => it !== item);
    },

    deleteFusion() {
      this.$confirm('Do you really want to delete this fusion?').then(res => {
        if (res) {
          axios.delete('/fusion/' + this.selectedFusion.id)
            .then(() => {
              axios.get('/fusion')
                .then(response => {
                  this.fusions = response.data;
                  this.snackbar = true;
                  this.snackbarText = 'Fusion deleted';
                  this.snackbarColor = 'error';
                  this.fusionDialog = false;
                });
            }).catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error deleting fusion';
            this.snackbarColor = 'error';
          });
        }
      });
    },

    saveFusion() {
      if (!this.$refs.fusionForm.validate()) {
        return;
      }

      axios.post('/fusion', this.selectedFusion)
        .then(() => {
          axios.get('/fusion')
            .then(response => {
              this.fusionDialog = false;
              this.fusions = response.data;
              this.snackbar = true;
              this.snackbarText = 'Fusion saved successfully';
              this.snackbarColor = 'success';
              this.currentFusion = JSON.parse(JSON.stringify(this.selectedFusion));
            });
        }).catch(() => {
        this.snackbar = true;
        this.snackbarText = 'Error saving fusion';
        this.snackbarColor = 'error';
      });
    },

    openFusionDialog(fusion) {
      this.fusionDialog = true;
      if (!fusion) {
        fusion = {
          items: []
        };
      }
      this.currentFusion = fusion;
      this.selectedFusion = JSON.parse(JSON.stringify(fusion));
    },
  },

  mounted() {
    axios.get('/fusion')
      .then(response => {
        this.fusions = response.data
      });

    axios.get('/pack')
        .then(response => {
          this.packs = response.data
          this.packs.unshift({
            id: null,
            name: 'All packs'
          })
        });

    axios.get('/item')
      .then(response => {
        this.items = response.data
      });

    this.searchPackId = localStorage.searchPackId;
  }
}
</script>
