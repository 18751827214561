import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'
import VueApexCharts from 'vue-apexcharts'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

Vue.config.productionTip = false;
Vue.axios.defaults.withCredentials = true;
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.interceptors.request.use(
  config => {
    config.headers["X-ZUnivers-RuleSetType"] = 'NORMAL'
    return config
  },
  error => Promise.reject(error)
)

Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    useNonce: false,
    token: localStorage.token, // Reuse tokens back from local storage
    idToken: localStorage.idToken,
    refreshToken: localStorage.refreshToken
  },
  config: {
    realm: 'zunivers',
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'zunivers'
  },
  onReady: keycloak => {
    if (keycloak.authenticated) {
      // Save tokens to local storage
      localStorage.setItem('token', keycloak.token);
      localStorage.setItem('idToken', keycloak.idToken);
      localStorage.setItem('refreshToken', keycloak.refreshToken);

      // Send token to backend
      Vue.axios.interceptors.request.use(
        config => {
          if (keycloak.token) {
            config.headers['Authorization'] = 'Bearer ' + keycloak.token;
          }
          return config;
        },
        error => {
          return Promise.reject(error)
        });

      // Logout if the backend refuse our token
      Vue.axios.interceptors.response.use(response => response, error => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
          keycloak.logout()
        } else if (status === 403) {
          document.getElementsByTagName("body")[0].innerHTML = '403 Forbidden'
        }

        return Promise.reject(error);
      })
    } else {
      // Authentication required
      keycloak.login();
      return;
    }

    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
})
