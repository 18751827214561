<template>
  <v-container>
    <h2 class="mt-5 mb-3">Maintenance</h2>

    <v-btn class="mr-4 mb-4" v-on:click="migrateDb()">Migrate DB</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="deleteUnusedDocuments()">Delete unused documents</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="syncAchievements()">Sync achievements</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="clearCache()">Clear cache</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="rotateChallenges()">Rotate challenges</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="rotateShop()">Rotate shop</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="rotateEvent()">Rotate event</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="announceTowerSeason()">Announce tower season</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="requestDiscordUpdate()">Discord update</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="rebuildItems()">Rebuild items</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="achievementTest()">Test achievement</v-btn>
    <v-btn class="mr-4 mb-4" v-on:click="forceGameChanged()">Force game changed</v-btn>

    <h2 class="mt-5 mb-3">Announcement</h2>

    <v-form>
      <v-select
          v-model="channelId"
          :items="channels"
          item-text="label"
          item-value="value"
          label="Channel"
          outlined
          required></v-select>
      <v-textarea outlined required v-model="message"></v-textarea>
    </v-form>
    <v-btn v-on:click="announce()">Send</v-btn>

    <h2 class="mt-5 mb-3">Configuration</h2>

    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Config name</th>
          <th class="text-left">Value</th>
          <th class="text-left"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="config of configs" :key="config.configType">
          <td>{{ config.configType }}</td>
          <td>
            <v-text-field v-model="config.value"></v-text-field>
          </td>
          <td>
            <v-btn color="primary" text @click="saveConfig(config)">Save</v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'Config',
  components: {
  },

  data() {
    return {
      configs: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      channelId: '824253593892290561',
      message: '',
      channels: [{
          label: '#zunivers',
          value: '827981884611887144'
        }, {
          label: '#actualité',
          value: '889192992664068176'
        }, {
          label: '#daily-bonus-luckyrayou',
          value: '808432657838768168'
        }, {
          label: '#invocation-im-fusion',
          value: '808432737697661008'
        }, {
          label: '#liste-profil-succès',
          value: '785944890453393408'
        }, {
          label: '#trade-marché',
          value: '813980380780691486'
        }, {
          label: '#forge',
          value: '813980453924896778'
        }, {
          label: '#vortex',
          value: '824253593892290561'
        }, {
          label: '#on-parle-de-zunivers',
          value: '785965752628543558'
        }, {
          label: '#infos-zunivers',
          value: '816718878612521051'
        }, {
          label: 'Staging - #général',
          value: '811962397534322699'
        }, {
          label: 'Staging - #zu',
          value: '811962466547924992'
        }
      ]
    }
  },

  methods: {
    announce() {
      axios.post('/app/announce', {
        channelId: this.channelId,
        message: this.message
      })
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Announcement sent';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error sending announcement';
          this.snackbarColor = 'error';
        });
    },

    saveConfig(config) {
      axios.post('/config', config)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Config saved successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error saving config';
          this.snackbarColor = 'error';
        });
    },

    migrateDb() {
      axios.post('/app/migrate')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'DB migrated successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error migrating DB';
          this.snackbarColor = 'error';
        });
    },

    deleteUnusedDocuments() {
      axios.post('/document/deleteUnusedDocuments')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Successfully deleted unused documents';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error deleting unused documents';
          this.snackbarColor = 'error';
        });
    },

    syncAchievements() {
      axios.post('/achievement/sync')
          .then(() => {
            this.snackbar = true;
            this.snackbarText = 'Achievements syncing in progress';
            this.snackbarColor = 'success';
          }).catch(() => {
        this.snackbar = true;
        this.snackbarText = 'Error syncing achievements';
        this.snackbarColor = 'error';
      });
    },

    clearCache() {
      axios.post('/app/clearCache')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Cache cleared successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error clearing cache';
          this.snackbarColor = 'error';
        });
    },

    rotateChallenges() {
      axios.post('/app/rotateChallenges')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Challenges rotated successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error rotating challenges';
          this.snackbarColor = 'error';
        });
    },

    rotateShop() {
      axios.post('/shop/rotate')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Shop rotated successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error rotating shop';
          this.snackbarColor = 'error';
        });
    },

    rotateEvent() {
      axios.post('/event/rotate')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Event rotated successfully';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error rotating event';
          this.snackbarColor = 'error';
        });
    },

    announceTowerSeason() {
      axios.post('/tower/announce')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Tower season announced';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error Announcing tower season';
          this.snackbarColor = 'error';
        });
    },

    requestDiscordUpdate() {
      axios.post('/app/requestDiscordUpdate')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Discord update requested';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error requesting Discord update';
          this.snackbarColor = 'error';
        });
    },

    rebuildItems() {
      axios.post('/item/rebuild')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Items rebuilded';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error rebuilding items';
          this.snackbarColor = 'error';
        });
    },

    achievementTest() {
      axios.post('/achievement/test')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Test achievement sent';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error sending test achievement';
          this.snackbarColor = 'error';
        });
    },

    forceGameChanged() {
      axios.post('/claim/valorantGameActive')
        .then(() => {
          this.snackbar = true;
          this.snackbarText = 'Game changed forced';
          this.snackbarColor = 'success';
        }).catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error forcing game changed';
          this.snackbarColor = 'error';
        });
    }
  },

  created() {
    axios.get('/config')
      .then(response => {
        this.configs = response.data
      });
  }
}
</script>
