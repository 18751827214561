<template>
  <v-container>
    <h2 class="mt-5 mb-3">Achievements</h2>

    <v-data-table
        :headers="headers"
        :items="achievements"
        :search="search"
        fixed-header
        multi-sort
        :sort-by="['category.name', 'index']"
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search achievements"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.isVisible="{ item }">
        <boolean-property :value="item.isVisible" />
      </template>
      <template v-slot:item.featOfStrength="{ item }">
        <boolean-property :value="item.featOfStrength" />
      </template>
      <template v-slot:item.isDeletable="{ item }">
        <boolean-property :value="item.isDeletable" />
      </template>
      <template v-slot:item.isAnnounceable="{ item }">
        <boolean-property :value="item.isAnnounceable" />
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openAchievementDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="achievementDialog" max-width="800">
      <v-card>
        <v-card-title>Edit achievement</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="achievementForm"
                  v-model="validAchievement">
                <v-text-field
                    v-model="selectedAchievement.index"
                    label="Index"
                    type="number"
                    :rules="[v => !!v || 'Index is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.name"
                    label="Name"
                    :rules="[v => !!v || 'Name is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.slug"
                    label="Slug"
                    :rules="[v => !!v || 'Slug is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.description"
                    label="Description"
                    :rules="[v => !!v || 'Description is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.score"
                    label="Score"
                    type="number"
                    :rules="[v => !!v || 'Score is required']"
                    outlined
                    required></v-text-field>
                <!-- TODO Category -->
                <v-checkbox
                    v-model="selectedAchievement.isVisible"
                    label="Visible"></v-checkbox>
                <v-checkbox
                    v-model="selectedAchievement.featOfStrength"
                    label="Prestige"></v-checkbox>
                <v-checkbox
                    v-model="selectedAchievement.isDeletable"
                    label="Deletable"></v-checkbox>
                <v-checkbox
                    v-model="selectedAchievement.isAnnounceable"
                    label="Announceable"></v-checkbox>
                <item-autocomplete
                    :items="items"
                    label="Reward item"
                    v-model="selectedAchievement.rewardItem"></item-autocomplete>
                <v-text-field
                    v-model="selectedAchievement.rewardItemShinyLevel"
                    label="Reward item shiny level"
                    type="number"
                    outlined></v-text-field>
                <!-- TODO Reward banner -->
                <v-text-field
                    v-model="selectedAchievement.rewardLoreDust"
                    label="Reward lore dust"
                    type="number"
                    outlined></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.handlerClass"
                    label="Handler class"
                    :rules="[v => !!v || 'Handler class is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedAchievement.handlerParameters"
                    label="Handler parameters"
                    outlined></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAchievement">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import BooleanProperty from "@/components/BooleanProperty";
import ItemAutocomplete from "@/components/ItemAutocomplete";

export default {
  name: 'Achievements',

  components: {
    ItemAutocomplete,
    BooleanProperty
  },

  data() {
    return {
      headers: [
        {
          text: 'Category',
          value: 'category.name'
        },
        {
          text: 'Index',
          value: 'index'
        },
        {
          text: 'Type',
          value: 'achievementType'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Visible',
          value: 'isVisible'
        },
        {
          text: 'Prestige',
          value: 'featOfStrength'
        },
        {
          text: 'Deletable',
          value: 'isDeletable'
        },
        {
          text: 'Announceable',
          value: 'isAnnounceable'
        },
        {
          text: 'Score',
          value: 'score'
        },
        {
          text: '',
          value: 'edit',
          filterable: false,
          sortable: false
        }
      ],
      search: '',
      achievements: [],
      items: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      achievementDialog: false,
      validAchievement: false,
      selectedAchievement: {}
    }
  },

  methods: {
    saveAchievement() {
      if (!this.$refs.achievementForm.validate()) {
        return;
      }

      axios.post('/achievement', this.selectedAchievement)
        .then(() => {
          axios.get('/achievement')
            .then(response => {
              this.achievementDialog = false;
              this.achievements = response.data;
              this.snackbar = true;
              this.snackbarText = 'Achievement saved successfully';
              this.snackbarColor = 'success';
            });
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error saving achievement';
          this.snackbarColor = 'error';
        });
    },

    openAchievementDialog(achievement) {
      this.achievementDialog = true;
      this.selectedAchievement = JSON.parse(JSON.stringify(achievement));
    }
  },

  created() {
    axios.get('/achievement')
      .then(response => {
        this.achievements = response.data
      })

    axios.get('/item')
        .then(response => {
          this.items = response.data
        })
  }
}
</script>
