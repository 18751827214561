<template>
  <span class="font-weight-bold" v-bind:class="{ 'green--text': value, 'red--text': !value }">{{value ? '✓' : '❌'}}</span>
</template>

<script>
export default {
  name: 'BooleanProperty',

  props: {
    value: {
      required: true
    }
  }
}
</script>