<template>
  <v-autocomplete v-bind:value="value"
                  :loading="loading"
                  :search-input.sync="search"
                  :items="userResults"
                  item-text="discordUserName"
                  item-value="id"
                  :outlined="outlined"
                  hide-no-data
                  return-object
                  :rules="rules"
                  v-on:input="$emit('input', $event)"
                  v-on:change="$emit('change', $event)"
                  :label="label">
    <template v-slot:item="data">
      {{data.item.discordUserName}} ({{data.item.ruleSetType.toLowerCase()}})
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";

export default {
    name: 'UserAutocomplete',

    props: {
      outlined: {
        default: true
      },
      value: {
        required: true
      },
      label: {
        required: true
      },
      rules: {
        required: false
      }
    },

    data() {
      return {
        loading: false,
        userResults: [],
        search: null,
      }
    },

    watch: {
      search(query) {
        if (this.loading) return;
        if (!query || query.length < 1) {
          this.userResults = [];
          return;
        }

        this.loading = true;
        axios.get('/public/user/autocomplete/' + encodeURIComponent(query))
          .then(response => {
            this.userResults = response.data;
          })
          .finally(() => {
            this.loading = false;
          })
      },

      value(value) {
        if (value) {
          this.userResults = [value];
        }
      }
    },

    mounted() {
      if (this.value) {
        this.userResults = [this.value];
      }
    }
  }
</script>