<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="7">
        <v-file-input
            class="flex-1-0"
            show-size
            label="Image"
            accept="image/*"
            :loading="fileInputLoading"
            outlined
            hide-details
            @change="selectFile($event)"
        ></v-file-input>
      </v-col>
      <v-col cols="5">
        <v-img class="ml-2" v-if="value" :src="value.url" />
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'DocumentUpload',

  props: ['value'],

  data() {
    return {
      fileInputLoading: false,
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: ''
    }
  },

  methods: {
    selectFile(file) {
      if (!file) {
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("contentType", file.type);

      this.fileInputLoading = true;
      axios.post('/document', formData)
        .then(response => {
          this.$emit('input', response.data);
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error uploading file';
          this.snackbarColor = 'error';
        })
        .finally(() => {
          this.fileInputLoading = false;
        });
    }
  }
}
</script>
