<template>
  <v-autocomplete v-bind:value="value"
                  :items="items"
                  item-text="name"
                  item-value="id"
                  outlined
                  deletable-chips
                  :label="label"
                  v-on:input="$emit('input', $event)"
                  v-on:change="$emit('change', $event)"
                  :multiple="multiple"
                  return-object>
    <template v-slot:selection="data">
      <v-chip close
              v-bind="data.attrs"
              :input-value="data.selected"
              v-on:click="data.select"
              v-on:click:close="remove(data.item)">
        <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <div class="d-flex align-center justify-center">
        <v-checkbox v-model="data.attrs.inputValue" />
        <span v-for="index in data.item.rarity" :key="index">⭐</span> {{data.item.name}}
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'ItemAutocomplete',
    props: ['value', 'items', 'multiple', 'label'],
    methods: {
      remove(item) {
        const index = this.value.indexOf(this.value.find(i => i.id === item.id));
        if (index >= 0) this.value.splice(index, 1);
        this.$emit('input', this.value);
      },
    }
  }
</script>
