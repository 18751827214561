import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import Items from "../views/Items";
import Config from "../views/Config";
import Users from "../views/Users";
import Activity from "../views/Activity";
import Posts from "../views/Posts";
import Achievements from "../views/Achievements";
import Events from "../views/Events";
import Towers from "../views/Towers";
import Fusions from "../views/Fusions";
import Themes from "../views/Themes";
import Tickets from "../views/Tickets";
import Packs from "@/views/Packs";
import Triggers from "@/views/Triggers";
import Claims from "@/views/Claims";
import Managers from "@/views/Managers";

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const routes = [
  {
    path: '/',
    component: Activity
  },
  {
    path: '/items',
    component: Items
  },
  {
    path: '/fusions',
    component: Fusions
  },
  {
    path: '/packs',
    component: Packs
  },
  {
    path: '/themes',
    component: Themes
  },
  {
    path: '/events',
    component: Events
  },
  {
    path: '/towers',
    component: Towers
  },
  {
    path: '/tickets',
    component: Tickets
  },
  {
    path: '/managers',
    component: Managers
  },
  {
    path: '/claims',
    component: Claims
  },
  {
    path: '/users/:discordId?',
    name: 'users',
    component: Users
  },
  {
    path: '/triggers',
    component: Triggers
  },
  {
    path: '/config',
    component: Config
  },
  {
    path: '/posts',
    component: Posts
  },
  {
    path: '/achievements',
    component: Achievements
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
