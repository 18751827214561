<template>
  <v-container>
    <h2 class="mt-5 mb-3">Claims</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openClaimDialog()">Add a claim</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="claims"
        :search="search"
        sort-by="beginDate"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search claims"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.isActive="{ item }">
        <boolean-property :value="item.isActive" />
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openClaimDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="claimDialog" max-width="800">
      <v-card>
        <v-card-title>{{selectedClaim.id ? 'Edit' : 'New'}} claim</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="claimForm"
                  v-model="validClaim">
                <v-text-field
                    v-model="selectedClaim.name"
                    label="Name"
                    outlined
                    required></v-text-field>
                <item-autocomplete
                    :items="items"
                    v-model="selectedClaim.item"></item-autocomplete>
                <v-checkbox
                    v-model="selectedClaim.isActive"
                    label="Active"></v-checkbox>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteClaim" v-if="selectedClaim.id">Delete claim</v-btn>
          <v-btn color="primary" text @click="saveClaim">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import BooleanProperty from "../components/BooleanProperty";
import ItemAutocomplete from "@/components/ItemAutocomplete";

export default {
  name: 'Claims',
  components: {
    ItemAutocomplete,
    BooleanProperty,
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Item',
          value: 'item.name'
        },
        {
          text: 'Active',
          value: 'isActive'
        },
        {
          text: '',
          value: 'edit',
          filterable: false,
          sortable: false
        }
      ],
      search: '',
      claims: [],
      items: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      claimDialog: false,
      validClaim: false,
      selectedClaim: {},
    }
  },

  methods: {
    deleteClaim() {
      this.$confirm('Do you really want to delete this claim?').then(res => {
        if (res) {
          axios.delete('/claim/' + this.selectedClaim.id)
            .then(() => {
              axios.get('/claim')
                .then(response => {
                  this.claims = response.data;
                  this.snackbar = true;
                  this.snackbarText = 'Claim deleted';
                  this.snackbarColor = 'error';
                  this.claimDialog = false;
                });
            }).catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error deleting claim';
            this.snackbarColor = 'error';
          });
        }
      });
    },

    saveClaim() {
      if (!this.$refs.claimForm.validate()) {
        return;
      }

      axios.post('/claim', this.selectedClaim)
        .then(() => {
          axios.get('/claim')
            .then(response => {
              this.claimDialog = false;
              this.claims = response.data;
              this.snackbar = true;
              this.snackbarText = 'Claim saved successfully';
              this.snackbarColor = 'success';
            });
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = 'Error saving claim';
          this.snackbarColor = 'error';
        });
    },

    openClaimDialog(claim) {
      this.claimDialog = true;
      if (!claim) {
        claim = {};
      }
      this.selectedClaim = JSON.parse(JSON.stringify(claim));
    }
  },

  created() {
    axios.get('/claim')
      .then(response => {
        this.claims = response.data
      });

    axios.get('/item')
        .then(response => {
          this.items = response.data
        })
  }
}
</script>
