<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="mt-5 mb-3">Effective drop rates</h2>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Rarity</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Drop rate</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rarityStat, index) of stats.rarityStats"
                :key="index"
                v-bind:class="{ 'amber--text': rarityStat.shinyLevel == 1 }">
              <td><span v-for="index in rarityStat.rarity" :key="index">⭐</span></td>
              <td>{{ rarityStat.quantity }}</td>
              <td>{{ percentRarity(rarityStat.quantity, stats.rarityStats) }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col>
        <h2 class="mt-5 mb-3">User statistics</h2>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <tbody>
            <tr>
              <th class="text-left">User count</th>
              <td>{{ stats.userCount }}</td>
            </tr>
            <tr>
              <th class="text-left">Loot count</th>
              <td>{{ stats.lootCount }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h2 class="mt-5 mb-3">Tower statistics (current season)</h2>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Tower floor</th>
              <th class="text-left">User count</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="stat of towerStats"
                :key="stat.index">
              <th class="text-left">
                <span v-if="stat.index >= 0 && stat.index !== null">{{ stat.index + 1 }}</span>
                <span v-if="stat.index === null">Stuck outside</span>
              </th>
              <td>{{ stat.count }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <h2 class="mt-5 mb-3">Latest loots</h2>

    <apexchart width="500" type="bar" :options="lootOptions" :series="lootSeries"></apexchart>

    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Loot date</th>
          <th class="text-left">Discord username</th>
          <th class="text-left">Loot type</th>
          <th class="text-left">Loot amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="loot of loots" :key="loot.id">
          <td>{{ loot.date }}</td>
          <td>
            <router-link :to="{ name: 'users', params: { discordId: loot.user.discordUserName }}">{{ loot.user.discordUserName }}</router-link>
          </td>
          <td>{{ loot.lootType }}</td>
          <td>{{ loot.amount }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h2 class="mt-5 mb-3">Potential cheaters</h2>

    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Discord username</th>
          <th class="text-left">Trade count</th>
          <th class="text-left">User traded with</th>
          <th class="text-left">User variance</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="cheater of cheaters" :key="cheater.userName">
          <td><router-link :to="{ name: 'users', params: { discordId: cheater.userName }}">{{ cheater.userName }}</router-link></td>
          <td>{{ cheater.tradeCount }}</td>
          <td>{{ cheater.userCount }}</td>
          <td>{{ cheater.spreadPercent }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Activity',
    components: {
    },

    data() {
      return {
        loots: [],
        lootOptions: {},
        lootSeries: [],
        stats: {},
        towerStats: [],
        cheaters: []
      }
    },

    methods: {
      percentRarity(quantity, rarityStats) {
        return (quantity / rarityStats.reduce((sum, rarityStat) => sum + rarityStat.quantity, 0) * 100).toFixed(3) + '%';
      }
    },

    created() {
      axios.get('/loot/stats')
        .then(response => {
          this.lootOptions = {
            chart: {
              type: 'bar',
              height: 200,
              width: '100%',
              toolbar: {
                show: false
              }
            },
            xaxis: {
              categories: response.data.map(lootStat => lootStat.day + '/' + lootStat.month + '/' + lootStat.year),
              labels: {
                show: false
              }
            },
            colors: ['#05b600'],
            dataLabels: {
              enabled: false
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            grid: {
              show: false
            },
            tooltip: {
              theme: 'dark'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '50%'
              },
            }
          };

          this.lootSeries = [{
            name: 'Loots',
            data: response.data.map(lootStat => lootStat.count)
          }];
        });

      axios.get('/loot')
        .then(response => {
          this.loots = response.data
        });

      axios.get('/app/cheaters')
        .then(response => {
          this.cheaters = response.data
        });

      axios.get('/inventory/stats')
        .then(response => {
          this.stats = response.data
        });

      axios.get('/tower/stats')
        .then(response => {
          this.towerStats = response.data
        });
    }
  }
</script>
