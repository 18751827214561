<template>
  <v-app>
    <v-app-bar
      app
      color="dark"
      dark>
      <v-container>
        <v-row justify="center">
          <div class="d-flex primary--text align-self-center font-weight-bold button">
            ZUNIVERS | ADMIN
          </div>

          <v-menu open-on-hover rounded bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ml-2"
                  text rounded
                  v-bind="attrs"
                  v-on="on">
                User management
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/">
                <v-list-item-title>Activity</v-list-item-title>
              </v-list-item>
              <v-list-item to="/users">
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover rounded bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ml-2"
                  text rounded
                  v-bind="attrs"
                  v-on="on">
                Item management
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/items">
                <v-list-item-title>Items</v-list-item-title>
              </v-list-item>
              <v-list-item to="/fusions">
                <v-list-item-title>Fusions</v-list-item-title>
              </v-list-item>
              <v-list-item to="/packs">
                <v-list-item-title>Packs</v-list-item-title>
              </v-list-item>
              <v-list-item to="/themes">
                <v-list-item-title>Themes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover rounded bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ml-2"
                  text rounded
                  v-bind="attrs"
                  v-on="on">
                Special events
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/events">
                <v-list-item-title>Events</v-list-item-title>
              </v-list-item>
              <v-list-item to="/towers">
                <v-list-item-title>Towers</v-list-item-title>
              </v-list-item>
              <v-list-item to="/tickets">
                <v-list-item-title>Tickets</v-list-item-title>
              </v-list-item>
              <v-list-item to="/claims">
                <v-list-item-title>Claims</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover rounded bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ml-2"
                  text rounded
                  v-bind="attrs"
                  v-on="on">
                Orchestrator
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/managers">
                <v-list-item-title>Managers</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover rounded bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ml-2"
                  text rounded
                  v-bind="attrs"
                  v-on="on">
                Settings
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/posts">
                <v-list-item-title>Posts</v-list-item-title>
              </v-list-item>
              <v-list-item to="/achievements">
                <v-list-item-title>Achievements</v-list-item-title>
              </v-list-item>
              <v-list-item to="/triggers">
                <v-list-item-title>Trigger inventories</v-list-item-title>
              </v-list-item>
              <v-list-item to="/config">
                <v-list-item-title>Configuration</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="d-flex grey--text align-self-center font-weight-bold ml-4" v-if="profile">
            Logged in as {{profile.attributes.discord_username[0]}}
          </div>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer class="mt-6" padless>
      <v-card
          flat
          tile
          width="100%"
          class="dark-gray white--text text-center">

        <v-card-text class="white--text">
          DB v{{status.dbVersion}} - API v{{status.applicationVersion}} - Web v{{webVersion}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>ZUnivers Admin</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',

  data() {
    return {
      webVersion: process.env.VUE_APP_VERSION,
      status: {},
      profile: null
    }
  },

  created() {
    this.$keycloak.loadUserProfile()
        .then(profile => {
          this.profile = profile;
        })

    axios.get('/app/status')
      .then(response => {
        this.status = response.data
      });
  }
};
</script>
