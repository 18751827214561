var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"mt-5 mb-3"},[_vm._v("Packs")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.packs,"search":_vm.search,"sort-by":"index","fixed-header":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search packs","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.isVisible",fn:function(ref){
var item = ref.item;
return [_c('boolean-property',{attrs:{"value":item.isVisible}})]}},{key:"item.isTemporary",fn:function(ref){
var item = ref.item;
return [_c('boolean-property',{attrs:{"value":item.isTemporary}})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.buildPreviewUrl(item)}},[_vm._v(" "+_vm._s(_vm.buildPreviewUrl(item))+" ")])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.checkPack(item)}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Check pack ")],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"800","eager":""},model:{value:(_vm.checkDialog),callback:function ($$v) {_vm.checkDialog=$$v},expression:"checkDialog"}},[_c('check-pack',{attrs:{"pack":_vm.checkedPack},on:{"close":function($event){_vm.checkDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }