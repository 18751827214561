<template>
  <v-container>
    <h2 class="mt-5 mb-3">Trigger inventories</h2>

    <v-btn class="align-self-center" v-on:click="start()" :disabled="loading">Start</v-btn>

    <v-progress-linear background-color="white" class="grey darken-3 black--text font-weight-black rounded mt-4" height="30"
                       :value="userDoneCount / userCount * 100">
      {{userDoneCount}}/{{userCount}}
    </v-progress-linear>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'Triggers',
  components: {
  },

  data() {
    return {
      loading: false,
      pageIndex: 0,
      pageSize: 1000,
      userCount: 0,
      userDoneCount: 0
    }
  },

  methods: {
    async start() {
      this.loading = true;
      this.loadUsers();
    },

    loadUsers() {
      axios.get('/user', {
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      })
          .then(async response => {
            this.userCount += response.data.length

            await Promise.all(
                response.data.map(
                    user => axios.post('/user/triggerInventoryChangedEvent/' + user.discordId)
                        .then(() => this.userDoneCount++)
                )
            );

            if (response.data.length === this.pageSize) {
              this.pageIndex++
              this.loadUsers()
            } else {
              this.loading = false
            }
          });
    }
  }
}
</script>
