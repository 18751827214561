<template>
  <v-container>
    <h2 class="mt-5 mb-3">Events</h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openEventDialog()">Add an event</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="events"
        :search="search"
        sort-by="beginDate"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search events"
            append-icon="mdi-magnify"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.pack="{ item }">
        <span v-if="item.pack">{{item.pack.name}} {{item.pack.year}}</span>
      </template>
      <template v-slot:item.beginDate="{ item }">
        {{formatDate(item.beginDate)}}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{formatDate(item.endDate)}}
      </template>
      <template v-slot:item.isPityEnabled="{ item }">
        <boolean-property :value="item.isPityEnabled" />
      </template>
      <template v-slot:item.isStarEnabled="{ item }">
        <boolean-property :value="item.isStarEnabled" />
      </template>
      <template v-slot:item.isOneTime="{ item }">
        <boolean-property :value="item.isOneTime" />
      </template>
      <template v-slot:item.isInversePack="{ item }">
        <boolean-property :value="item.isInversePack" />
      </template>
      <template v-slot:item.isSubscription="{ item }">
        <boolean-property :value="item.isSubscription" />
      </template>
      <template v-slot:item.isPreventDuplicate="{ item }">
        <boolean-property :value="item.isPreventDuplicate" />
      </template>
      <template v-slot:item.isAutomated="{ item }">
        <boolean-property :value="item.isAutomated" />
      </template>
      <template v-slot:item.items="{ item }">
        <v-chip v-for="it of item.items" :key="it.id" class="ma-1">
          <span v-for="index0 in it.rarity" :key="index0">⭐</span> {{it.name}}
        </v-chip>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openEventDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="eventDialog" max-width="800">
      <v-card>
        <v-card-title>{{selectedEvent.id ? 'Edit' : 'New'}} event</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                  ref="eventForm"
                  v-model="validEvent">
                <v-text-field
                    v-model="selectedEvent.name"
                    label="Name"
                    outlined
                    required></v-text-field>
                <v-select
                    v-model="selectedEvent.pack"
                    label="Pack"
                    :items="packs"
                    item-value="id"
                    :item-text="getPackText"
                    :rules="[v => !!v || 'Pack is required']"
                    outlined
                    return-object>
                </v-select>
                <document-upload v-model="selectedEvent.image"></document-upload>
                <item-autocomplete
                    :items="filteredItems(selectedEvent.pack)"
                    :multiple="true"
                    v-model="selectedEvent.items"></item-autocomplete>
                <v-datetime-picker
                    label="Begin date"
                    v-model="selectedEvent.beginDate"></v-datetime-picker>
                <v-datetime-picker
                    label="End date"
                    v-model="selectedEvent.endDate"></v-datetime-picker>
                <v-text-field
                    v-model="selectedEvent.balanceCost"
                    label="Cost"
                    type="number"
                    :rules="[v => !!v || 'Cost is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedEvent.loreDustCost"
                    label="Lore dust cost"
                    type="number"
                    :rules="[v => !!v || 'Lore dust cost is required']"
                    outlined
                    required></v-text-field>
                <v-checkbox
                    v-model="selectedEvent.isOneTime"
                    label="One-time usage"></v-checkbox>
                <v-text-field
                    v-model="selectedEvent.dailyLimit"
                    label="Daily limit"
                    type="number"
                    outlined></v-text-field>
                <v-checkbox
                    v-model="selectedEvent.isPityEnabled"
                    label="Pity enabled"></v-checkbox>
                <v-checkbox
                    v-model="selectedEvent.isStarEnabled"
                    label="STAR enabled"></v-checkbox>
                <v-checkbox
                    v-model="selectedEvent.isInversePack"
                    label="Inverse pack"></v-checkbox>
                <v-checkbox
                    v-model="selectedEvent.isSubscription"
                    label="Subscription"></v-checkbox>
                <v-checkbox
                    v-model="selectedEvent.isPreventDuplicate"
                    label="Prevent duplicate of rarity 4 items"></v-checkbox>
                <v-checkbox
                    v-model="selectedEvent.isAutomated"
                    label="Include in the automated rotation"></v-checkbox>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteEvent" v-if="selectedEvent.id">Delete event</v-btn>
          <v-btn color="primary" text @click="saveEvent">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import {format, parse} from 'date-fns';
import VDatetimePicker from "../components/DatetimePicker";
import DocumentUpload from "../components/DocumentUpload";
import ItemAutocomplete from "../components/ItemAutocomplete";
import BooleanProperty from "@/components/BooleanProperty";

export default {
    name: 'Events',
    components: {
      BooleanProperty,
      ItemAutocomplete,
      DocumentUpload,
      VDatetimePicker
    },

    data() {
      return {
        headers: [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Pack',
            value: 'pack'
          },
          {
            text: 'Items',
            value: 'items'
          },
          {
            text: 'Begin date',
            value: 'beginDate'
          },
          {
            text: 'End date',
            value: 'endDate'
          },
          {
            text: 'One-time usage',
            value: 'isOneTime',
            align: 'center'
          },
          {
            text: 'Pity enabled',
            value: 'isPityEnabled',
            align: 'center'
          },
          {
            text: 'STAR enabled',
            value: 'isStarEnabled',
            align: 'center'
          },
          {
            text: 'Inverse pack',
            value: 'isInversePack',
            align: 'center'
          },
          {
            text: 'Subscription',
            value: 'isSubscription',
            align: 'center'
          },
          {
            text: 'Prevent duplicate',
            value: 'isPreventDuplicate',
            align: 'center'
          },
          {
            text: 'Automated',
            value: 'isAutomated',
            align: 'center'
          },
          {
            text: '',
            value: 'edit',
            filterable: false,
            sortable: false
          }
        ],
        search: '',
        events: [],
        items: [],
        packs: [],
        snackbar: false,
        snackbarColor: 'success',
        snackbarText: '',
        eventDialog: false,
        validEvent: false,
        selectedEvent: {}
      }
    },

    methods: {
      getPackText(pack) {
        return `${pack.name} ${pack.year ? pack.year : ''}`
      },

      filteredItems(pack) {
        if (!pack) {
          return this.items;
        }

        return this.items.filter(item => item.pack.id === pack.id);
      },

      formatDate(dateString) {
        if (!dateString) {
          return '';
        }
        return format(parse(dateString, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date()), 'dd/MM/yyyy HH:mm');
      },

      deleteEvent() {
        this.$confirm('Do you really want to delete this event? All event participations will be deleted.').then(res => {
          if (res) {
            axios.delete('/event/' + this.selectedEvent.id)
              .then(() => {
                axios.get('/event')
                  .then(response => {
                    this.events = response.data;
                    this.snackbar = true;
                    this.snackbarText = 'Event deleted';
                    this.snackbarColor = 'error';
                    this.eventDialog = false;
                  });
              }).catch(() => {
              this.snackbar = true;
              this.snackbarText = 'Error deleting event';
              this.snackbarColor = 'error';
            });
          }
        });
      },

      saveEvent() {
        if (!this.$refs.eventForm.validate()) {
          return;
        }

        axios.post('/event', this.selectedEvent)
          .then(() => {
            axios.get('/event')
              .then(response => {
                this.eventDialog = false;
                this.events = response.data;
                this.snackbar = true;
                this.snackbarText = 'Event saved successfully';
                this.snackbarColor = 'success';
              });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error saving event';
            this.snackbarColor = 'error';
          });
      },

      openEventDialog(event) {
        this.eventDialog = true;
        if (!event) {
          event = {};
        }
        this.selectedEvent = JSON.parse(JSON.stringify(event));
      }
    },

    created() {
      axios.get('/event')
        .then(response => {
          this.events = response.data
        });

      axios.get('/pack')
        .then(response => {
          this.packs = response.data
        });

      axios.get('/item')
        .then(response => {
          this.items = response.data
        })
    }
  }
</script>
